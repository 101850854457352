export const DropType = [
    "LOOT_DROP_ALL",
    "LOOT_DROP_ONE"
];

//TODO: New controller to pass in enums?
export const LootType = [
    "LOOT_CURRENCY",
    "LOOT_CARD_SPECIFIC",
    "LOOT_CARD_RANDOM",
    "LOOT_CARD_RANDOM_FROM_REGION",
    "LOOT_PLAY_SPECIFIC",
    "LOOT_PLAY_RANDOM",
    "LOOT_EQUIPMENT_SPECIFIC",
    "LOOT_EQUIPMENT_RANDOM",
    "LOOT_MENTOR_SPECIFIC",
    "LOOT_BULLET_PACK",
    "LOOT_LTE_OBJECTS",
    "LOOT_NUM_LOOT_TYPES"
]

export const SpreadContexts = [
    "SPREAD_ABSOLUTE_QUALITY",
    "SPREAD_RELATIVE_TOP_DECK_QUALITY",
    "SPREAD_SPECIFIC_ID"
]