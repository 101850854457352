import React, { useState, useEffect } from 'react';
import getUrl from 'util/getUrl';
import Button from 'components/Button';
import fetcher from 'util/fetcher';

import classes from './FillInPlaymakerSeasonStats.module.css';

function FillInPlaymakerSeasonStats() {
    const [formData, setFormData] = useState({ Season: "", StartWeek: "", EndWeek: "", SeasonType:"" });
    const [response, setResponse] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState("");
    const url = `${getUrl()}/Playmaker/BackfillNGSStats`;

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!formData.Season || !formData.StartWeek || !formData.EndWeek || !formData.SeasonType) {
            setError("All fields are required");
            return;
        }
        setError("");
        setResponse(false);
        setSubmitted(true);
        async function fetchData() {
            try {
                const response = await fetcher(`${url}?Season=${formData.Season}&StartWeek=${formData.StartWeek}&EndWeek=${formData.EndWeek}&SeasonType=${formData.SeasonType}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    xhrFields: { withCredentials: true }
                });
                if (!response.ok) {
                    setError(`Error while proceess ${response.status} ${response.statusText}`)
                    return;
                } else {
                    const data2 = await response.json();
                    setResponse(data2.started);
                }
            } catch (error) {
                setError(`Error while proceess ${error}`)
            }
            setSubmitted(false);
        }
        fetchData();

    };
    return (
        <div className={classes.root}>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="Season">Season:</label>
                    <input type="number" id="Season" name="Season" value={formData.Season} onChange={handleChange} />
                </div>
                <div>
                <label htmlFor="StartWeek">Start Week:</label>
                    <input type="number" id="StartWeek" name="StartWeek" value={formData.StartWeek} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="EndWeek">End Week:</label>
                    <input type="number" id="Season" name="EndWeek" value={formData.EndWeek} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="SeasonType">Season Type:</label>
                    <input type="text" id="SeasonType" name="SeasonType" value={formData.SeasonType} onChange={handleChange} />
                </div>
                <Button
                    text="Start BackFill NGS Stats"
                    isLoading={submitted}
                    disabled={submitted}
                />
                {/*{submitted ? <button class='btn' type="submit" disabled>Submit</button> : <button class='btn' type="submit" >Submit</button>}*/}
                {response ? <div className={classes.success}>Successfully Started</div> : ""}
                {error ? <div className={classes.error}>{error}</div> : ""}
            

            </form>
        </div>
    );
}

export default FillInPlaymakerSeasonStats;