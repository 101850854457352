export default function buildPlayListTable(cardList, playDefinitions, qualitySettings) {
    const headers = [
        {
            value: 'id',
            label: 'DefID',
            type: 'number'
        },
        {
            value: 'name',
            label: "Name",
            type: 'string'
        },
        {
            value: 'phaseType',
            label: "Phase Type",
            type: 'string'
        },
        {
            value: 'playType',
            label: "Play Type",
            type: 'string'
        },
        {
            value: 'winPercent',
            sortBy: "winPercentValue",
            label: "Win %",
            type: 'string'
        },
        {
            value: "winPercentValue",
            type: "number",
            isSort: true
        },
        {
            value: "quality",
            sortBy: "qualityValue",
            label: "Quality",
            type: "string"
        },
        {
            value: "qualityValue",
            type: "number",
            isSort: true
        },
    ];

    const innerHeaders = [
        {
            value: "level",
            label: "Level",
            type: "number"
        },
        {
            value: "ovr",
            label: "OVR",
            type: "number"
        }
    ];

    const data = [];
    if (cardList?.length > 0) {
        playDefinitions?.PlayCardList?.forEach(playDef => {
            const plays = cardList?.filter(card => card.PlayCardDefID === playDef.ID);
            const { Name, Quality, PhaseType, PlayType, WinPercentage } = playDef;
            const quality = qualitySettings.QualityList.find(def => def.ID === Quality);
            let nodes = [];
            if (plays.length > 0) {
                plays.forEach((play, i) => {
                    nodes.push({
                        id: i + 1,
                        level: play.Level,
                        ovr: play.OVR,
                    });
                })
                const cardNode = {
                    id: playDef.ID,
                    name: Name,
                    quality: `${quality?.NameStr} (${Quality})`, //TODO: Make generic formatter util for quality
                    qualityValue: Quality,
                    phaseType: PhaseType,
                    playType: PlayType,
                    winPercent: `${Math.floor(WinPercentage * 100)}%`,
                    winPercentValue: WinPercentage,
                    nodes
                }
                data.push(cardNode);
            }
        })
    }
    return [headers, innerHeaders, data];
}