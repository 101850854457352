import React, { useEffect, useState, useMemo } from 'react';
import TextInput from 'components/TextInput';
import classes from "../EventCalendar.module.css";
import Button from 'components/Button';
import fetcher from 'util/fetcher';
import { toast } from 'react-hot-toast';

function CustomEventPopup(props) {
    const [name, setName] = useState("");
    const [game, setGame] = useState("");
    const [server, setServer] = useState("");
    const [details, setDetails] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [eventType, setEventType] = useState("CUSTOM");

    async function AddCustomEvent() {
        try {
            const response = await fetcher(`https://webportal.cdgsrv.com/EventTimeline/AddEvent?EventName=${name}&Game=${game}&StartTime=${startTime}&EndTime=${endTime}&Server=${server}&EventType=${eventType}&Details=${details}`, { method: 'POST' });
            toast.success("Event Added");
        }
        catch (ex) {
            console.log("Exception", ex);
        }
    }
    return (
        <div className={`${classes.popup}`}>
            <div className={classes.content}>
                <TextInput
                    label="Name"
                    value={name}
                    onChange={e => setName(e.target.value) }
                />
                <TextInput
                    label="Game"
                    value={game}
                    onChange={e => setGame(e.target.value)}
                />
                <TextInput
                    label="Server"
                    value={server}
                    onChange={e => setServer(e.target.value)}
                />
                <TextInput
                    label="Details"
                    value={details}
                    onChange={e => setDetails(e.target.value)}
                />
                <label>Start Time:</label>
                <input aria-label="Date and time" type="datetime-local" onChange={e => setStartTime(e.target.value)} />
                <label>End Time:</label>
                <input aria-label="Date and time" type="datetime-local" onChange={e => setEndTime(e.target.value)} />
            </div>
            <div className={classes.add_custom_padding}>
                <Button onClick={AddCustomEvent} text="Add" ></Button>
            </div>
        </div>
    )

}
export default CustomEventPopup;