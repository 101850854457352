import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import Table from 'components/Table';
import classes from "./GameInfo.module.css";
import Loader from 'components/Loader';
import Tabs from 'components/Tabs/Tabs';
import CurrencyOffers from './GameInfo/CurrencyOffers';
import Catalog from './GameInfo/Catalog';
import Packs from './GameInfo/Packs';
import useFileData from 'util/useFileData';
import toast from 'react-hot-toast';

function GameInfo() {
    const tabValues = [
        { name: 'catalog', label: 'Catalog' },
        { name: 'packs', label: 'Packs' },
        { name: 'currencyOffers', label: 'Currency Offers' }
    ];
    const [selectedTab, setSelectedTab] = useState(tabValues[0].name);
    //Catalog Data
    const { data: cardsSeason1 } = useFileData('Game/CardDefinitions_Season1.json');
    const { data: playsSeason1 } = useFileData('Game/PlayCardDefinitions_Season1.json');
    const { data: equipmentSeason1 } = useFileData('Game/EquipmentDefinitions_Season1.json');
    const { data: qualitySettings } = useFileData('Game/QualitySettings.json');
    const { data: cardStatSettings } = useFileData('Game/CardStatDefinitionSettings.json');
    const { data: teamSettings } = useFileData('Game/NFLTeamDefinitionSettings.json');
    //
    //Pack Data
    const { data: mainMenuPackStore, isLoading: isMainLoading } = useFileData('Game/MainMenuPackSettings.json');
    const { data: seasonPackStore, isLoading: isSeasonLoading } = useFileData('Game/SeasonsPackSettings.json');
    const { data: playmakersPackStore, isLoading: isPlaymakersLoading } = useFileData('Game/PlaymakersPackSettings.json');
    const { data: lteRushPackStore, isLoading: isRushLoading } = useFileData('Game/LTERushPackSettings.json');
    const { data: bpForwardPackStore, isLoading: isForwardLoading } = useFileData('Game/BPForwardPackSettings.json');
    const { data: bpProgressionPackStore, isLoading: isProgressionLoading } = useFileData('Game/BPProgressionPackSettings.json');
    const { data: bpCollectiblesPackStore, isLoading: isCollectiblesLoading } = useFileData('Game/BPCollectiblesPackSettings.json');
    const { data: loginPromotionPackStore, isLoading: isLoginPromotionLoading } = useFileData('Game/LoginPromotionPackSettings.json');
    const { data: hardCurrencyOffersPackStore, isLoading: isHardCurrencyLoading } = useFileData('Game/HardCurrencyPackSettings.json');

    function buildCardList(data, qualityData, statData, teamData) {
        var tempCardList = [];
        if (data) {
            for (var i = 0; i < data?.CardList?.length; ++i) {
                var card = {
                    Name: `${data.CardList[i].FirstName} ${data.CardList[i].LastName}`,
                    ID: data.CardList[i].ID,
                    QualityValue: data.CardList[i].Quality, //To Do: replace with quality name
                    TeamValue: data.CardList[i].NFLTeam, //To Do: replace with Team Name
                    Position: data.CardList[i].Position,
                    SynergyValue: data.CardList[i].SynergyType,//To Do: replace with string
                    ArchetypeValue: data.CardList[i].Archetype, //To Do: Replace with Archtype Name
                    Stats: data.CardList[i].Stats.join(", ")
                };
                if (qualityData) {
                    for (var j = 0; j < qualityData?.QualityList?.length; ++j) {
                        if (data.CardList[i].Quality === qualityData.QualityList[j].ID) {
                            card.Quality = `(${data.CardList[i].Quality}) ${qualityData.QualityList[j].NameStr}`;
                            break;
                        }
                    }    
                }
                if (statData) {
                    for (var j = 0; j < statData?.CardArchetypes?.length; ++j) {
                        if (data.CardList[i].Archetype === statData.CardArchetypes[j].ID) {
                            card.Archetype = `(${data.CardList[i].Archetype}) ${statData.CardArchetypes[j].Desc}`;
                            break;
                        }
                    }  
                }
                if (teamData) {
                    for (var j = 0; j < teamData?.TeamList?.length; ++j) {
                        if (data.CardList[i].NFLTeam === teamData.TeamList[j].ID) {
                            card.Team = `(${data.CardList[i].NFLTeam}) ${teamData.TeamList[j].CityNameStr} ${teamData.TeamList[j].NameStr}`;
                            break;
                        }
                    }  
                }
                tempCardList.push(card);
            }
        }
        return tempCardList;
    }
    function buildPlayList(data, qualityData) {
        var tempPlayList = [];
        if (data) {
            for (var i = 0; i < data?.PlayCardList?.length; ++i) {
                var play = {
                    Name: data.PlayCardList[i].Name,
                    ID: data.PlayCardList[i].ID,
                    QualityValue: data.PlayCardList[i].Quality,
                    PhaseType: data.PlayCardList[i].PhaseType,
                    PlayType: data.PlayCardList[i].PlayType,
                    WinPercent: data.PlayCardList[i].WinPercentage,
                    YardsGained: data.PlayCardList[i].YardsGainedOnWin,
                    YardsLost: data.PlayCardList[i].YardsLostOnLoss
                }
                if (qualityData) {
                    for (var j = 0; j < qualityData?.QualityList?.length; ++j) {
                        if (data.PlayCardList[i].Quality === qualityData.QualityList[j].ID) {
                            play.Quality = `(${data.PlayCardList[i].Quality}) ${qualityData.QualityList[j].NameStr}`;
                            break;
                        }
                    }
                }
                tempPlayList.push(play);
            }
        }
        return tempPlayList;
    }
    function buildEquipmentList(data, qualityData) {
        var tempEquipmentList = [];
        if (data) {
            for (var i = 0; i < data?.EquipmentList?.length; ++i) {
                var equip = {
                    Name: data.EquipmentList[i].Name,
                    ID: data.EquipmentList[i].ID,
                    QualityValue: data.EquipmentList[i].Quality,
                    Stats: ""
                }
                var tempStats = "";
                for (var j = 0; j < data.EquipmentList[i].StatBuffs.length; ++j) {
                    tempStats += `${data.EquipmentList[i].StatBuffs[j].Stat}: ${data.EquipmentList[i].StatBuffs[j].Amount}`;
                    if (j != data.EquipmentList[i].StatBuffs.length - 1) {
                        tempStats += " / ";
                    }
                }
                equip.Stats = tempStats;
                if (qualityData) {
                    for (var j = 0; j < qualityData?.QualityList?.length; ++j) {
                        if (data.EquipmentList[i].Quality === qualityData.QualityList[j].ID) {
                            equip.Quality = `(${data.EquipmentList[i].Quality}) ${qualityData.QualityList[j].NameStr}`;
                            break;
                        }
                    }
                }
                tempEquipmentList.push(equip);
            }
        }
        return tempEquipmentList;
    }
    function buildArchetypeList(data) {
        var tempArchetypeList = [];
        if (data) {
            for (var i = 0; i < data?.CardArchetypes?.length; ++i) {
                var archetype = {
                    Name: data.CardArchetypes[i].Desc,
                    ID: data.CardArchetypes[i].ID,
                    Stat1: data.CardArchetypes[i].StatList[0],
                    Stat2: data.CardArchetypes[i].StatList[1],
                    Stat3: data.CardArchetypes[i].StatList[2],
                    Stat4: data.CardArchetypes[i].StatList[3]
                }
                tempArchetypeList.push(archetype);
            }
        }
        return tempArchetypeList;
    }

    const cardList = buildCardList(cardsSeason1, qualitySettings, cardStatSettings, teamSettings);
    const playList = buildPlayList(playsSeason1, qualitySettings);
    const equipmentList = buildEquipmentList(equipmentSeason1, qualitySettings);
    const archetypeList = buildArchetypeList(cardStatSettings);
    const isLoaded = cardList?.length > 0 && playList?.length > 0 && equipmentList?.length > 0 && archetypeList?.length > 0;

    const isLoadingPack = (isMainLoading || isSeasonLoading || isLoginPromotionLoading || isPlaymakersLoading || isRushLoading || isForwardLoading || isProgressionLoading || isCollectiblesLoading || isHardCurrencyLoading);

    //TODO: If store is missing, then revalidation will trigger rerender as filter needs to be called again - Is there a better way to keep packs from needing to rerender?
    const packs = [
        mainMenuPackStore,
        seasonPackStore,
        loginPromotionPackStore,
        playmakersPackStore,
        lteRushPackStore,
        bpForwardPackStore,
        bpProgressionPackStore,
        bpCollectiblesPackStore,
    ]?.filter((e) => e); //Filter to remove any undefined packs, so missing setting won't break component

    return (
        <div className={classes.root}>
            {isLoaded &&
                <div>
                    <Tabs
                        tabValues={tabValues}
                        selectedTab={selectedTab}
                        setSelected={setSelectedTab}
                    />
                    {selectedTab === tabValues[0].name ?
                        <Catalog
                            cardsSettings={cardList}
                            playsSettings={playList}
                            equipmentSettings={equipmentList}
                            archetypeSettings={archetypeList}
                        />
                        : selectedTab === tabValues[1].name ?
                            <Packs
                                isLoading={isLoadingPack}
                                packs={packs}
                            />
                            : selectedTab === tabValues[2].name ?
                                <CurrencyOffers
                                    hardCurrencyStoreSettings={hardCurrencyOffersPackStore}
                                />
                                : ''
                    }
                </div>
            }
        </div>

    );
} 

export default GameInfo