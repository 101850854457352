import useSWR from 'swr';
import getUrl from 'util/getUrl';
import getUrlParam from 'util/getUrlParam';
import fetcher from 'util/fetcher';
import hasValue from 'util/hasValue';

export default function usePlaymakersData(userSearch) {
    const { data, error, isLoading, isValidating, mutate } = useSWR(hasValue(userSearch) ? `${getUrl()}/Playmaker/GetPlayerRewards?userId=${userSearch}` : null, (...args) => fetcher(...args).then(res => res.json()), {
        revalidateOnFocus: false
    })

    return {
        data: data,
        isLoading: isLoading || isValidating,
        isError: error,
        mutate: mutate
    }
}