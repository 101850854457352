export default function buildCardListTable(cardList, cardDefinitions, qualitySettingsData) {
    const headers = [
        {
            value: 'id',
            label: 'DefID',
            type: 'number'
        },
        {
            value: "name",
            label: "Name",
            type: "string"
        },
        {
            value: "position",
            label: "Position",
            type: "string"
        },
        {
            value: "quality",
            sortBy: "qualityValue",
            label: "Quality",
            type: "string"
        },
        {
            value: "qualityValue",
            type: "number",
            isSort: true
        }
    ];
    //TODO: Add instance id when avaliable
    const innerHeaders = [
        {
            value: "level",
            label:  "Level",
            type: 'number'
        },
        {
            value: 'stat1',
            label: "Strength", //TODO: Hard coding stat names - can I get these from settings?
            type: 'number'
        },
        {
            value: 'stat2',
            label: "Block",
            type: 'number'
        },
        {
            value: 'stat3',
            label: "Agility",
            type: 'number'
        },
        {
            value: 'stat4',
            label: "Awareness",
            type: 'number'
        },
        {
            value: 'overall',
            label: "Overall",
            type: 'number'
        } //TODO: Add slots to inner
    ]
    const data = [];
    if (cardList?.length > 0) {
        cardDefinitions?.CardList?.forEach((cardDef, i) => {
            const cards = cardList?.filter(card => card.CardDefID === cardDef.ID);
            const quality = qualitySettingsData?.QualityList.find(qualityDef => qualityDef.ID === cardDef.Quality);
            let nodes = [];
            if (cards.length > 0) {
                cards.forEach((card, i) => {
                    nodes.push({
                        id: i + 1,
                        level: card.Level,
                        stat1: card.Stats[0],
                        stat2: card.Stats[1],
                        stat3: card.Stats[2],
                        stat4: card.Stats[3],
                        overall: card.Overall,
                    });
                });
                const cardNode = {
                    id: cardDef.ID,
                    name: cardDef?.FirstName + " " + cardDef?.LastName,
                    quality: `${quality?.NameStr} (${cardDef.Quality})`,
                    qualityValue: cardDef.Quality,
                    position: cardDef.Position,
                    nodes
                }
                data.push(cardNode);
            }
        });
    }
    return [headers, innerHeaders, data];
}