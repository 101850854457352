import { useEffect, useRef } from 'react';
import classes from './Modal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

function Modal(props) {

    const { modalContent, setModalContent } = props;

    const modalRef = useRef();

    const { title, body } = modalContent;

    //Listener to close modal on click outside
    const handleOutsideClick = (e) => {
        if (!modalRef.current.contains(e.target)) setModalContent(null)
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    }, []);

    return (
        <div
            className={classes.root}
            ref={modalRef}
        >
            <div className={classes.titleRow}>
                <h4>{title}</h4>
                <FontAwesomeIcon
                    icon={faX}
                    className={classes.x}
                    size={"xl"}
                    onClick={() => setModalContent(null)}
                />
            </div>
            <div className={classes.body}>
                {body}
            </div>
        </div>
    );
}

export default Modal