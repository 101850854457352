//TODO: Is there somewhere else to get the country codes? An endpoint or SKUs or something?
//TODO: Confirm country codes are correct for project, currently just placeholder for testing
export default [
    {
        value: "",
        label: 'N/A',
    },
    {
        value: "US",
        label: "US"
    },
    {
        value: "CA",
        label: "CA"
    },
    {
        value: "DE",
        label: "DE"
    },
    {
        value: "UK",
        label: "UK"
    },
    {
        value: "JP",
        label: "JP"
    },
    {
        value: "TCH",
        label: "TCH"
    },
]