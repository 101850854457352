import React from 'react';
import classes from './Tab.module.css';

function Tab(props) {

    const { tab, selectedTab, setSelected } = props;

    const { label, name }  = tab;
    const isSelected = selectedTab === name;

    return (
        <div
            className={`${classes.root} ${isSelected ? classes.selected : ''}`}
            onClick={() => setSelected(name)}
        >
            {label ?? name ?? ''}
        </div>
    )
}

export default Tab;