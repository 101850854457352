import React, { useEffect, useState, useMemo } from 'react';
import ReadText from 'components/ReadText';
import classes from "../EventCalendar.module.css";

function DetailsPopup(props) {
    const {
        details,
        eventType,
        game,
        server,
        startTime,
        endTime
    } = props;


    return (
        <div className={`${classes.popup}`}>
            <div className={classes.content }>
                <ReadText
                    label="Event Type"
                    value={eventType }
                />
                <ReadText
                    label="Game"
                    value={game}
                />
                <ReadText
                    label="Server"
                    value={server}
                />
                <ReadText
                    label="Details"
                    value={details}
                />
                <ReadText
                    label="Start Time"
                    value={startTime}
                />
                <ReadText
                    label="End Time"
                    value={endTime}
                />
            </div>
        </div>
    )

}
export default DetailsPopup;