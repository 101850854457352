import React from 'react';
import classes from './TextInput.module.css';

export default function TextInput(props) {
    const { label, isLoading, disabled = false, innerRef = null, ...remainingProps } = props;

    return (
        <div className={classes.root}>
            {label && <label htmlFor={`input-${label}`} className={classes.label}>{label}</label>}
            <input
                id={`input-${label}`}
                className={classes.input}
                type="text"
                disabled={isLoading || disabled}
                ref={innerRef}
                {...remainingProps}
            />
        </div>
    )
}
