const banDurationOptions =  [
    {
        label: "24 Hours",
        value: 1
    },
    {
        label: "3 Days",
        value: 3
    },
    {
        label: "7 Days",
        value: 7
    },
    {
        label: "Permanent",
        value: -1
    },
];

export default banDurationOptions;