//TODO: Pass in other event settings
export default function buildEventsTable(playmakers, playmakersChildren, rush, bpPlayForward, progression, roadToFame, bpCollectibles) {
    const headers = [
        {
            value: 'id',
            label: 'ID',
            type: 'number'
        },
        {
            value: 'event',
            label: 'Event',
            type: 'string'
        },
        {
            value: 'preview',
            label: 'Preview',
            type: 'string'
        },
        {
            value: 'start',
            label: 'Start',
            type: 'string',
            defaultSort: true,
            defaultReverse: true
        },
        {
            value: 'live',
            label: 'Live',
            type: 'string'
        },
        {
            value: 'end',
            label: 'End',
            type: 'string'
        },
        {
            value: 'post',
            label: 'Post',
            type: 'string'
        },
    ]
    
    let data = [];
    //TODO: Add in other events when avaliable
    if (playmakers) {
        const { Start, End, Preview, Live, Post } = playmakers.SeasonSchedule;
        data.push({
            id: playmakers.SeasonId,
            event: 'Playmakers',
            start: Start,
            end: End,
            preview: Preview,
            live: Live,
            post: Post,
            parent: playmakers
        })
    }
    if (playmakersChildren) {
        playmakersChildren.forEach(child => {
            const { Start, End, Preview, Live, Post } = child.Schedule;
            data.push({
                id: child.Week,
                event: 'Playmakers Week',
                start: Start,
                end: End,
                preview: Preview,
                live: Live,
                post: Post,
                parent: playmakers
            });
        });
    }
    if (rush) {
        const { Start, End, Preview, Live, Post } = rush.Schedule;
        data.push({
            id: rush.EventId,
            event: 'Endzone Rush',
            start: Start,
            end: End,
            preview: Preview,
            live: Live,
            post: Post,
            parent: rush
        })
    }
    if (bpPlayForward) {
        const { Start, End, Preview, Live, Post } = bpPlayForward.Schedule;
        data.push({
            id: "N/A",
            event: 'Play it Forward',
            start: Start,
            end: End,
            preview: Preview,
            live: Live,
            post: Post,
            parent: bpPlayForward
        })
    }
    if (progression) {
        const { Start, End, Preview, Live, Post } = progression.LootDisplayDef.Availability.Schedule;
        data.push({
            id: "N/A",
            event: 'Progression Bullet Pack',
            start: Start,
            end: End,
            preview: Preview,
            live: Live,
            post: Post,
            parent: progression
        })
    }
    if (roadToFame) {
        const { Start, End, Preview, Live, Post } = roadToFame.Schedule;
        data.push({
            id: "N/A",
            event: 'Road to Glory',
            start: Start,
            end: End,
            preview: Preview,
            live: Live,
            post: Post,
            parent: roadToFame
        })
    }
    if (bpCollectibles) {
        const { Start, End, Preview, Live, Post } = bpCollectibles?.LootDisplayDef?.Availability?.Schedule;
        data.push({
            id: "N/A",
            event: 'Collectibles Bullet Pack',
            start: Start,
            end: End,
            preview: Preview,
            live: Live,
            post: Post,
            parent: bpCollectibles
        })
    }
    return [headers, data];
}