import getUrl from 'util/getUrl';
import hasValue from 'util/hasValue';
import fetcher from 'util/fetcher';

export default async function NBARewindRequest(date, wait, errorId, reset) {
    const res = await fetcher(`${getUrl()}/NBARewind/GetData?date=${date.toISOString()}&check=${wait}&errorId=${errorId}&reset=${reset}`);
    const data = await res.json();
    return data;
}

export async function SaveFileData(date, json) {
    const res = await fetcher(`${getUrl()}/NBARewind/SaveFileDataWebportal`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: "POST",
        body: JSON.stringify({
            date: date,
            json: JSON.stringify(json), //Json required to be string instead of object
            description: "Updated weights",
            etag: "",
            game: "splash",
            failedId: -1
        })
    });
    const data = await res.json();
    return data;
}
export async function UpdateProductionData(date, json, env) {
    const res = await fetcher(`${getUrl("production")}/NBARewind/SaveFileDataWebportal`, {
        headers: {
            'Content-Type': 'application/json',
        }, 
        method: "POST",
        body: JSON.stringify({
            date: date,
            json: JSON.stringify(json), //Json required to be string instead of object
            description: `Migrate from ${env}`,
            etag: "",
            game: "splash",
            failedId: -1
        })
    });
    const data = await res.json();
    return data;
}

export async function GetGeneratedGames(id) {
    const res = await fetcher(`${getUrl()}/NBARewind/GetGeneratedGames?id=${id}`);
    const data = await res.json();
    return data;
}

