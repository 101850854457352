import Select from 'react-select';

//Wrapper for react-select to set up standard styling / options
function SelectWrapper(props) {

    const { children, index, ...rest } = props;

    const styles = {
        container: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: index ? `${index}` : "10",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: "0px",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            width: 'max-content',
            borderRadius: "0px",
        }),
    };

    return (
        <Select
            styles={styles}
            {...rest}
        >
            {children}
        </Select>
    );
}

export default SelectWrapper;
