import React, { useEffect, useState } from 'react';
import Table from 'components/Table';
import classes from "./NBARewind.module.css";
import DatePicker from "components/DatePicker"
import nbaRewindRequest, { SaveFileData, UpdateProductionData, GetGeneratedGames } from '../util/nbaRewindRequest';
import { toast } from 'react-hot-toast';
import Accordian from 'components/Accordian';
import buildNBARewindTable from 'util/buildNBARewindTables/buildNBARewindTable';
import buildTopPlaysTable from 'util/buildNBARewindTables/buildTopPlaysTable';
import buildObjectivesTable from 'util/buildNBARewindTables/buildObjectivesTable';
import Loader from 'components/Loader';
import Button from 'components/Button';
import WeightEditButton from './NBARewind/WeightEditButton';
import JsonEditButton from './NBARewind/JsonEditButton';
import { useSearchParams } from 'react-router-dom';
import useFileData from 'util/useFileData';
import { NBATeam, objectiveValue } from 'constants/NBARewindTypes';

function NBARewind() {
    const [date, setDate] = useState(null);
    const [rewindData, setRewindData] = useState(null);
    const [gameData, setGameData] = useState(null);
    const [polling, setPolling] = useState(null);
    const [wait, setWaiting] = useState(false);
    const [editing, setEditing] = useState(false);
    const [newData, setNewData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedObjectives, setSelectedObjectives] = useState([]);
    const [selectedHighlights, setSelectedHighlights] = useState([]);

    const [searchParams] = useSearchParams();

    const env = searchParams.get("environment");

    const { data: rewindLeaderboard, isLoading: leaderboardLoading } = useFileData('Game/Rewind/Rewind_LeaderboardSettings.json');
    const { data: livePlayers, isLoading: livePlayersLoading } = useFileData('Game/Rewind/Rewind_LiveDataPlayerSettings.json');

    const [headers, tableData] = buildNBARewindTable(rewindData);

    //Stop editing weights if new data fetched or selected game changes
    useEffect(() => {
        setEditing(false);
        if (rewindData) setNewData(structuredClone(rewindData));
    }, [gameData, rewindData]);


    const setDateField = async (dateInput,reset) => {
        setGameData(null);
        setRewindData(null);
        setSelectedObjectives([]);
        setSelectedHighlights([]);
        setDate(dateInput);
        setWaiting(true);
        await poll(dateInput, 0, -1, reset ?? false);
    }

    useEffect(() => {
        const paramDate = searchParams?.get("rewindDate");
        const presetDate = new Date(paramDate);
        if (paramDate && presetDate) {
            setDateField(presetDate);
        }
    }, [searchParams])

    const poll = async (initialDate, count, errorId,reset) => {
        try {

            const newResult = await nbaRewindRequest(initialDate, count > 0, errorId, reset);
            if (newResult.err) {
                toast.error(newResult.err);
                setWaiting(false);
            } else if (newResult.wait === false) {
                setRewindData(JSON.parse(newResult.data));
                getSelectedData(initialDate).finally(setWaiting(false));
            } else if (newResult.wait) { // Call the function again after 60 seconds (or your chosen interval)
                if (count > 1000) {
                    setWaiting(false);
                    toast.error("Error getting data");
                } else {
                    setPolling(setTimeout(function () { poll(initialDate, count + 1, newResult.errorId); }, 1000));
                }
            }
        } catch (error) {
            console.error('error', error);
            toast.error("Error getting data");
        }
    };

    async function getSelectedData(dateInput) {
        const nextDate = new Date(dateInput);
        nextDate.setDate(dateInput.getDate() + 1);
        const leaderboard = rewindLeaderboard.Defs.find(item => {
            const start = new Date(item.Start);
            start.setHours(0, 0, 0, 0);
            const end = new Date(item.End);
            end.setHours(0, 0, 0, 0);
            return nextDate >= start && nextDate <= end;
        });
        if (leaderboard) {
            const dateIndex = leaderboard?.RemapDates?.findIndex(item => {
                let remapped = new Date(item.RemappedDate);
                if (remapped.toDateString() === dateInput.toDateString()) return true;
                return false;
            }); //Find date matching current search - ignoring timestamp
            if (dateIndex >= 0) {
                const dateKey = `L${leaderboard.ID},D${dateIndex}`;
                const res = await GetGeneratedGames(dateKey);
                const genGames = res.success.reduce((result, item) => {
                    if (result && Date.parse(result?.GeneratedDate) > Date.parse(item?.GeneratedDate)) return result;
                    else return item;
                });
                let highlights = [];
                let objectives = [];
                const gameHighlights = genGames.lstHighlights.map(item => {
                    let highlight = JSON.parse(item.D)
                    const [first, last] = getPlayerName(highlight?.PlayerVCRefID ?? highlight?.HighlightVCRefID);
                    highlight['NBAPlayer'] = { FullName: first ? `${first} ${last}` : 'N/A' };
                    const home = NBATeam?.[highlight?.Home?.TeamID];
                    const away = NBATeam?.[highlight?.Away?.TeamID];
                    highlight['game'] = home && away ? `${home} vs ${away}` : 'N/A';
                    return highlight
                });
                highlights = highlights.concat(gameHighlights);
                const gameObjectives = genGames.lstReplays.reduce((result, item) => {
                    const obj = JSON.parse(item.Objective.D) ?? null;
                    if (obj) {
                        obj['Value'] = obj?.[objectiveValue?.[obj?.Type]] ?? 'N/A';
                        const [first, last] = getPlayerName(obj.PlayerVCRefID);
                        obj['FeaturedPlayer'] = { FirstName: first, LastName: last };
                        const home = NBATeam?.[item?.Home?.TeamID];
                        const away = NBATeam?.[item?.Away?.TeamID];
                        obj['game'] = home && away ? `${home} vs ${away}` : 'N/A';
                        result.push(obj);
                    }
                    return result;
                }, []);
                objectives = objectives.concat(gameObjectives);
                setSelectedHighlights(highlights);
                setSelectedObjectives(objectives);
            }
        }
    }

    const capitalize = (name) => {
        const names = name.split(' ');
        return names.map(item => item[0].toUpperCase() + item.substring(1).toLowerCase()).join(" ");
    }

    const getPlayerName = (refId) => {
        const player = livePlayers.LiveDataPlayers.find(player => player.VCRefID === refId);
        let first = null, last = null;
        if (player) {
            first = capitalize(player?.FirstName);
            last = capitalize(player?.LastName);
        }
        return [first, last];

    }

    // Call the polling function initially
    const onTableClick = (row) => {
        setGameData(row)
    }
    const resetData = async () => {
        setDateField(date,true);
    }
    const cancelPolling = () => {
        clearTimeout(polling);
        setWaiting(false);
    }
    const [topHeaders, topRows] = buildTopPlaysTable(gameData?.Highlights, editing, setNewData, gameData?.Id);
    const [objectiveHeaders, objectiveRows] = buildObjectivesTable(gameData?.Objectives);

    const [selectedHighlightsHeaders, selectedHighlightsRows] = buildTopPlaysTable(selectedHighlights);
    const [selectedObjHeaders, selectedObjRows] = buildObjectivesTable(selectedObjectives, true);
    async function updateJson(updatedJson = null) {
        setLoading(true);
        const json = updatedJson ?? newData;
        const res = await SaveFileData(date, json);
        //Use local data to update display, since request after save will fetch data before backend is updated
        if (res?.success) {
            setRewindData(json);
            setGameData(prev => json?.Games?.find(game => game?.Id === prev?.Id) ?? prev);
        }
        setLoading(false);
    }

    const topAction = <WeightEditButton
        editing={editing}
        setEditing={setEditing}
        onSubmit={updateJson}
        setNewData={setNewData}
        data={rewindData}
    />;


    async function handleMigrate() {
        if (window.confirm("This will migrate all game weights for the selected day to production. Are you sure you want to do this?")) {
            setLoading(true);
            const res = await UpdateProductionData(date, newData, env);
            if (res?.success) {
                setRewindData(newData);
                setGameData(prev => newData?.Games.find(game => game?.Id === prev?.Id));
            }
            setLoading(false);
        }
    }

    return (
        <div className={classes.root } >
            <div className={classes.dateWrapper}><label>Date:</label> <DatePicker
                isRange={false}
                onChange={setDateField}
                value={date}
                disabled={wait}
                className={classes.datepicker}
                clearIcon={null}
            />
            </div>
            {wait || loading ?
                <div ><Loader /> {!loading && <Button onClick={cancelPolling} text="Cancel" />}
            </div> :
                rewindData &&
                    <>
                    {selectedHighlightsRows.length > 0 && selectedObjRows.length > 0 &&
                        <div className={classes.selectedWrapper}>
                            <Accordian
                                label={`Selected Highlights (${selectedHighlightsRows.length})`}
                            >
                                <Table
                                    columns={selectedHighlightsHeaders}
                                    data={selectedHighlightsRows}
                                    showActions={false}
                                    showPagination={false}
                                />
                            </Accordian>
                            <Accordian
                                className={classes.selected}
                                label={`Selected Objectives (${selectedObjRows.length})`}
                            >
                                <Table
                                    columns={selectedObjHeaders}
                                    data={selectedObjRows}
                                    showActions={false}
                                    showPagination={false}
                                />
                            </Accordian>
                        </div>
                    }
                    {tableData.length > 0 ?
                        <>
                            <h5
                                className={classes.tableLabel}
                            >
                                {`Games played on ${date?.toDateString()}`}
                            </h5>
                            <Table
                                columns={headers}
                                data={tableData}
                                onClick={onTableClick }
                                styles={{ maxHeight: '30rem' }}
                            />
                        </>
                        : 
                        <div>No games on this day</div>
                    } 
                    {env && !env.includes("prod") && <div className={classes.migrate}>
                        <Button onClick={handleMigrate} text={"Migrate data to Production"} isLoading={wait || loading} />
                    </div>}
                    {gameData &&
                        <div>
                            <div className={classes.titleWrapper}>
                                <h2 className={classes.title}>{gameData.Home.Name} ({gameData.HomePoints}) at {gameData.Away.Name} ({gameData.AwayPoints}) </h2>
                                <JsonEditButton
                                    date={date}
                                    gameData={gameData}
                                    updateJson={updateJson}
                                    rewindData={rewindData}
                                />
                            </div>
                            <div className={classes.margins}>
                                <Accordian
                                    label={`Starting Lineup`}
                                >
                                    <div className={classes.flexdiv } >
                                        <div className={classes.flexinner }>
                                            <div className={classes.bold }>{gameData.Home.Name}</div>
                                        {gameData?.HomeStartingLineup?.map((item, index) => {
                                            return <div>{item.FullName}</div>
                                        })}
                                        </div>
                                        <div className={classes.flexinner }>
                                            <div className={classes.bold }>{gameData.Away.Name}</div>
                                        {gameData?.AwayStartingLineup?.map((item, index) => {
                                            return <div>{item.FullName}</div>
                                        })}
                                        </div>
                                    </div>
                                </Accordian>
                                {topRows ?
                                        <div className={classes.topPlays}>
                                        <Accordian
                                            label={`Highlights (${topRows.length})`}
                                            defaultOpen
                                        >
                                        <Table
                                            columns={topHeaders}
                                            data={topRows}
                                            customActions={[topAction]}
                                            showPagination={false}
                                            hideDefaultActions={true}
                                        />
                                        </Accordian>
                                        </div>
                                    : 
                                       <div>No Highlights found</div>
                                }
                            </div>
                            {objectiveRows?.length > 0 && <Accordian label={`Objectives (${objectiveRows.length})`}>
                                <Table
                                    columns={objectiveHeaders}
                                    data={objectiveRows}
                                    showPagination={false}
                                    showActions={false}
                                />
                            </Accordian>}
                            {<div>
                                <Button onClick={resetData} text="Verify Data" />
                            </div>}
                        </div>
                        }
                    </>
            }
        </div>
    );
}

export default NBARewind