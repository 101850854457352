import { useState } from 'react';
import classes from './EventManager.module.css';
import useFileData from 'util/useFileData';
import Table from 'components/Table';
import buildEventsTable from 'util/buildEventManagerTables/buildEventsTable';
import Loader from 'components/Loader';
import SelectedEvent from './EventManager/SelectedEvent';
import PlayerSearch from './UserInfo/PlayerSearch';
import usePlaymakersData from 'util/usePlaymakersData';
import useLTEData from 'util/useLTEData';

function EventManager(props) {
    const [userSearch, setUserSearch] = useState(null);

    const { data: playmakersSettings } = useFileData('Game/PlaymakersSettings.json');
    const { data: rushSettings } = useFileData('Game/LTERushSettings.json');
    const { data: playForwardSettings } = useFileData('Game/BPForwardSettings.json');
    const { data: progressionSettings } = useFileData('Game/BPProgressionSettings.json');
    const { data: roadToFameSettings } = useFileData('Game/LTERoadToFameSettings.json');
    const { data: bpCollectiblesSettings } = useFileData('Game/BPCollectiblesSettings.json');


    //TODO: Add BPCollectibles to events manager function

    const { data: playmakerUserData, isPlaymakersLoading, mutate: mutatePlaymakers } = usePlaymakersData(userSearch);
    const { data: rushUserData, isLoading: isRushLoading, mutate: mutateRush } = useLTEData(userSearch, 0); //0 - Rush Type
    const { data: roadUserData, isLoading: isRoadLoading, mutate: mutateRoad } = useLTEData(userSearch, 2);

    const mutate = () => {
        mutatePlaymakers();
        mutateRush();
        mutateRoad();
    }

    const isLoading = isPlaymakersLoading || isRushLoading | isRoadLoading;

    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventHeaders, eventData] = buildEventsTable(playmakersSettings, null, rushSettings, playForwardSettings, progressionSettings, roadToFameSettings, bpCollectiblesSettings);

    const setSelected = row => {
        setSelectedEvent(row);
    }

    return (
        <div className={classes.root}>
            <PlayerSearch
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                sharedOption={true}
                mutate={mutate}
                isLoading={isLoading}
            />
            {!eventData?.length ?
                <Loader /> :
                <div className={classes.content}>
                    <Table
                        columns={eventHeaders}
                        data={eventData}
                        onClick={setSelected}
                    />
                    <>
                        {selectedEvent &&  
                            <SelectedEvent
                                event={selectedEvent}
                                setSelectedEvent={setSelectedEvent}
                                userSearch={userSearch}
                            />
                        }
                    </>
                </div>
            }
        </div>
    );
}

export default EventManager;
