import useSWR, { mutate } from 'swr';
import getUrl from 'util/getUrl';
import fetcher from 'util/fetcher';

export default function useLogQuery(logObject) {
    const url = logObject ? `${getUrl()}/SupportLogViewer/GetLogs` : null;

    const { data, error, isLoading, isValidating, mutate } = useSWR({url: url, logObject: logObject},
        ({ url, logObject }) => 
            fetcher(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify(
                    logObject
                )
            }).then(res =>
                res.json()
            )
        , {
        revalidateOnFocus: false
    });

    return {
        data: data ?? [],
        isLoading: isLoading || isValidating,
        isError: error,
        mutate,
    }
    
}

export function mutateLogs() {
    mutate(key => {
        return typeof key === 'object' && key?.url?.includes(`/SupportLogViewer/GetLogs`)
    }            ,
        undefined,
        { revalidate: true }
    );
}