import React, { useEffect, useState } from 'react';
import classes from "./UserInfo.module.css";
import Layout from 'components/Layout';
import PlayerSearch from './UserInfo/PlayerSearch';
import AdvancedSearch from './UserInfo/AdvancedSearch';
import OtherSearch from './UserInfo/OtherSearch';
import Tabs from 'components/Tabs/Tabs';
import SearchResults from './UserInfo/SearchResults';
import usePlayer from 'util/usePlayer';
import { useSearchParams } from 'react-router-dom';
import hasValue from 'util/hasValue';
import Loader from 'components/Loader';

function UserInfo() {

    const tabValues = [
        { name: 'playerId', label: 'Player ID Search'} ,
        { name: 'advanced', label: 'Advanced Search'} ,
        { name: 'other', label: 'Other Search'} ,
    ];

    const [searchParams, setSearchParams] = useSearchParams(); //Use to set shared playerId

    const [userSearch, setUserSearch] = useState("");
    const [selectedTab, setSelectedTab] = useState(tabValues[0].name);
    const [advancedUsers, setAdvancedUsers] = useState([]);

    const updateUserSearch = (query) => {
        setUserSearch(query);
        //Set shared id for other tiles
        window.parent.postMessage([`${searchParams.get('game')}-${searchParams.get('environment')}-${searchParams.get('tab')}-playerId`, query], '*'); //Handle via postMessage to parent window, parent sends message to child iframe to update search params
    }

    const { data: playerData, isLoading: playerLoading, isError, mutate } = usePlayer(userSearch);

    return (
        <div className={classes.root}>
            <Tabs
                tabValues={tabValues}
                selectedTab={selectedTab}
                setSelected={setSelectedTab}
            />
            {selectedTab === tabValues[0].name ?
                <PlayerSearch
                    userSearch={userSearch}
                    setUserSearch={updateUserSearch}
                    isLoading={playerLoading}
                    mutate={mutate}
                />
                : selectedTab === tabValues[1].name ?
                    <AdvancedSearch
                        userSearch={userSearch}
                        setUserSearch={updateUserSearch}
                        advancedUsers={advancedUsers}
                        setAdvancedUsers={setAdvancedUsers}
                    />
                : selectedTab === tabValues[2].name ?
                    <OtherSearch
                        userSearch={userSearch}
                        setUserSearch={updateUserSearch}
                        advancedUsers={advancedUsers}
                        setAdvancedUsers={setAdvancedUsers}
                    />
                : ''
            }
            <div className={classes.divider} />
            {playerLoading ?
                <Loader />
                : hasValue(playerData?.CDID) ?
                    <SearchResults playerId={playerData.CDID}/> : null
            }
        </div>
    );
}

export default UserInfo;