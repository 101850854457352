import React from 'react';
import Table from 'components/Table';
import classes from "../GameInfo.module.css";
import buildPackTable from 'util/buildPackTable';

function CurrencyOffers(props) {
    const {
        hardCurrencyStoreSettings,
    } = props;

    const [packTableHeaders, packSettings] = buildPackTable(hardCurrencyStoreSettings, true);

    const isLoaded = packSettings.length > 0;
    return (
        <div>
            {isLoaded &&
                <Table
                    columns={packTableHeaders}
                    data={packSettings}
                    styles={{ maxHeight: '25rem' }}
                />
            }
        </div>
    );
} 

export default CurrencyOffers