import { Home } from "tiles/Home";
import Layout from "components/Layout";
import UserInfo from "tiles/UserInfo";
import UserCollection from "tiles/UserCollection";
import SupportLogViewer from 'tiles/SupportLogViewer';
import EventCalendar from "./pages/EventCalendar";
import UserMail from "tiles/UserMail";
import FillInPlaymakerSeasonStats from "tiles/FillInPlaymakerSeasonStats";
import GameInfo from "tiles/GameInfo";
import EventManager from 'tiles/EventManager';
import NBARewind from 'tiles/NBARewind';

const wrappedElement = (element, game = 'phoenix') => {
    return (
        <Layout game={game}>
            {element}
        </Layout>
    )
}

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/tiles/',
      children: [
          {
              path: 'userInfo',
              element: wrappedElement(<UserInfo />)
          },
          {
              path: 'userCollection',
              element: wrappedElement(<UserCollection />)
          },
          {
              path: 'supportLogViewer',
              element: wrappedElement(<SupportLogViewer />)
          },
          {
              path: 'userMail',
              element: wrappedElement(<UserMail />)
          },
          {
              path: 'FillInPlaymakerSeasonStats',
              element: wrappedElement(<FillInPlaymakerSeasonStats />)
          },
          {
              path: 'gameInfo',
              element: wrappedElement(<GameInfo />)
          },
          {
              path: 'eventManager',
              element: wrappedElement(<EventManager />)
          },
          {
              path: 'nbaRewind',
              element: wrappedElement(<NBARewind />, "splash")
          }
      ]
  },
  {
    path: '/calendar',
      element: wrappedElement(<EventCalendar />, "")
  }
];

export default AppRoutes;

