import { useState, useRef } from 'react';
import classes from './BanModal.module.css';
import Button from 'components/Button';
import Select from 'components/Select';
import BanDurationOptions from 'constants/BanDurationOptions';
import Loader from 'components/Loader';
import getUrl from 'util/getUrl';
import fetcher from 'util/fetcher';
import { toast } from 'react-hot-toast';

export default function BanModal(props) {
    const { banStatus, setModalContent, mutate, CDID } = props;
    const noteRef = useRef();
    const [duration, setDuration] = useState(banStatus ? BanDurationOptions[3] : BanDurationOptions[0]); //1, 3, 7, -1
    const [loading, setLoading] = useState(false);

    const banUser = async () => {
        setLoading(true);
        const note = noteRef.current.value;
        await fetcher(`${getUrl()}/UserInfo/BanUser?userId=${CDID}&duration=${duration.value}&note=${note}`, { method: 'POST' });
        mutate();
        setLoading(false);
        setModalContent(null)
    }

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <label> {`Duration`}
                    <Select 
                        className={classes.select}
                        options={BanDurationOptions}
                        onChange={val => setDuration(val)}
                        value={duration}
                        isDisabled={banStatus}
                    />
                </label>
                <label> {`Note`}
                    <textarea
                        ref={noteRef}
                    />
                </label>
            </div>
            <div className={classes.actions}>
                <Button
                    onClick={() => setModalContent(null)}
                    text="Cancel"
                />
                <Button
                    onClick={() => banUser()}
                    text="Ban User"
                    isLoading={loading}
                />
            </div>
        </div>
    );
}