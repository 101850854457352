import React, { useState, useEffect } from 'react';
import classes from './SupportLogViewer.module.css';
import useFileData from 'util/useFileData';
import PlayerSearch from './UserInfo/PlayerSearch';
import Select from 'components/Select';
import formatLogOptions from 'util/formatLogOptions';
import Button from 'components/Button';
import SupportLog from './SupportLogViewer/SupportLog';
import parseLogQuery from 'util/parseLogQuery';
import useValidateUser from 'util/useValidateUser';
import onEnterKeyValidation from 'util/onEnterKeyWithValidation';
import { mutateLogs } from 'util/useLogQuery';

function SupportLogViewer() {

    const [userSearch, setUserSearch] = useState("");
    const [selectedLogTypes, setSelectedLogTypes] = useState([]);
    const [searchList, setSearchList] = useState([]); //Only update when searching
    const [validSearch, setValidSearch] = useState("");

    const { isLoading: isValidatingUser } = useValidateUser(validSearch); //Check if player search is still validating query

    const { data: logSettingsData } = useFileData('Tools/SupportLogViewSetting.json');
    const { data: currencyDefinitionsData } = useFileData('Game/CurrencyDefinitionSettings.json');
    const { data: qualityDefinitionsData } = useFileData('Game/QualitySettings.json');

    //Getting data here so already loaded / cached in SupportLog component
    const { data: cardDefinitionsData  } = useFileData('Game/CardDefinitions_Season1.json');
    const { data: playCardDefinitionsData  } = useFileData('Game/PlayCardDefinitions_Season1.json');
    const { data: equipmentDefinitions } = useFileData('Game/EquipmentDefinitions_Season1.json');

    //TODO: Card Definitions_Season1 - Need to future proof info gathering here - How?

    const logOptions = logSettingsData?.Logs?.length > 0 && qualityDefinitionsData && currencyDefinitionsData ? formatLogOptions(logSettingsData.Logs, currencyDefinitionsData, qualityDefinitionsData) : [];
    const hasLogOptions = logOptions?.length > 0;

    const selectAllValue = hasLogOptions && logOptions?.every(log => selectedLogTypes.some(selected => selected.value === log.value))

    const selectAllLogTypes = () => {
        if (selectAllValue) setSelectedLogTypes([]);
        else {
            setSelectedLogTypes(logOptions);
        }
    }

    const getLogs = () => {
        mutateLogs();
        const logQueries = parseLogQuery(userSearch, selectedLogTypes, logSettingsData.Logs)
        if (logQueries?.length > 0) setSearchList(logQueries);
    }

    const canGetLogs = selectedLogTypes?.length > 0 && userSearch?.length > 0;

    const enterKey = (e) => {
        onEnterKeyValidation(e, getLogs, canGetLogs);
    }

    useEffect(() => {
        document.addEventListener("keydown", enterKey);
        return () => document.removeEventListener("keydown", enterKey);
    }, []);

    return (
        <div
            className={classes.root}
        >
            <PlayerSearch
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                sharedOption={true}
                disabled={selectedLogTypes.length === 0}
                noSearch={true}
            />
            <div className={classes.logActionsWrapper}>
                <label
                    className={classes.logTypeSelect}
                > {`Log Types`}
                    <Select
                        isMulti
                        isClearable
                        closeMenuOnSelect={false}
                        options={logOptions}
                        value={selectedLogTypes}
                        onChange={selectedOptions => setSelectedLogTypes(selectedOptions)}
                    />
                    <label className={classes.selectAllLabel}>
                        {`Select All`}
                        <div>
                            <input
                                type="checkbox"
                                value={selectAllValue}
                                checked={selectAllValue}
                                onChange={selectAllLogTypes}
                                />
                        </div>
                    </label>
                </label>
                <Button
                    text={"Get Logs"}
                    className={classes.searchButton}
                    onClick={getLogs}
                    loading={isValidatingUser}
                    disabled={!canGetLogs}
                />
            </div>
            <div className={classes.resultsWrapper}>
                {searchList?.length > 0 &&
                    searchList.map((log, i) => <SupportLog
                        log={log}
                        logSettings={logSettingsData.Logs}
                        key={log?.log}
                    />)
                }
            </div>
        </div>
    );
}

export default SupportLogViewer;