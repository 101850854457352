import useSWR from 'swr';
import getUrl from 'util/getUrl';
import hasValue from 'util/hasValue';
import fetcher from 'util/fetcher';

export default function usePlayerBanStatus(playerId) {
    const { data, error, isLoading, isValidating, mutate } = useSWR(hasValue(playerId) ? `${getUrl()}/UserInfo/GetBanStatus?userId=${playerId}` : null, (...args) => fetcher(...args).then(res => res.json()), {
        revalidateOnFocus: false
    });

    return {
        data: data,
        isLoading: isLoading || isValidating,
        isError: error,
        mutate,
    }
}