import { useEffect, useState, useRef } from 'react';
import classes from './AdvancedSearch.module.css';
import usePlayer from 'util/usePlayer';
import SearchType from './AdvancedSearch/SearchType';
import AdvancedOptions from './AdvancedSearch/AdvancedOptions';
import CurrentFilter from './AdvancedSearch/CurrentFilter';
import { elasticTypes } from 'constants/advancedSearchOptions';
import getElasticVal from 'util/getElasticAdvancedSearch';
import advancedSearchRequest from 'util/advancedSearchRequest';
import Loader from 'components/Loader';
import UserSelect from 'tiles/UserInfo/UserSelect';
import { toast } from 'react-hot-toast';

function AdvancedSearch(props) {

    const {
        userSearch,
        setUserSearch,
        advancedUsers,
        setAdvancedUsers
    } = props;
    
    const jsonRef = useRef(null); //Can use edited json value without tightly controlling variable (only use if ref is avaliable, otherwise fall back to currentJson)
    const [selectedType, setSelectedType] = useState(null);
    const [currentFilters, setCurrentFilters] = useState([]);
    const [currentJson, setCurrentJson] = useState(null);
    const [showJson, setShowJson] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        if (currentFilters.length > 0) { //Using useEffect to update currentJson as state, and allow to be updated outside of select flow if wanted
            //Split up filters by must / must not, add in separately!
            const notFilters = currentFilters.filter(filter => filter.boolean.value.includes('not'));
            const standardFilters = currentFilters.filter(filter => !filter.boolean.value.includes('not'));
            let newJson = {
                bool: {}
            }
            newJson.bool["must"] = standardFilters.map(filter => getElasticVal(filter.field, filter.input, filter.boolean.value))
                .concat(selectedType.value?.split("_").map((type, i) => getElasticVal(elasticTypes[i], type, "match")));
            if (notFilters.length > 0) newJson.bool["must_not"] = notFilters.map(filter => getElasticVal(filter.field, filter.input, filter.boolean.value?.split(" ")[1]));
            setCurrentJson(JSON.stringify(newJson));
        } else setCurrentJson(null);
    }, [currentFilters])

    const playerSearch = async () => {
        setSearchLoading(true);
        const newResult = await advancedSearchRequest(currentJson);
        setAdvancedUsers(newResult);
        if (newResult.length > 0 && !newResult[0]?.err) setUserSearch(newResult[0].value);
        else if (newResult[0]?.err) {
            toast.error(newResult[0].err);
        }
        else {
            toast.success("No results found for advanced search");
        }
        setSearchLoading(false);
    }
    
    return (
        <div className={classes.root}>
            <SearchType
                value={selectedType}
                setValue={setSelectedType}
            />
            {selectedType &&
                <AdvancedOptions
                    state={selectedType}
                    setCurrentFilters={setCurrentFilters}
                    showJson={showJson}
                    setShowJson={setShowJson}
                    playerSearch={playerSearch}
                    searchLoading={searchLoading}
                    currentJson={currentJson}
                />
            }
            {showJson && currentJson &&
                <textarea
                    className={classes.json}
                    value={currentJson}
                    onChange={e => setCurrentJson(e.target.value)}
                    ref={jsonRef}
                />
            }
            <div className={classes.filterWrapper}>
                <div className={classes.filters}>
                    {currentFilters.map(filter => 
                        <CurrentFilter
                            key={`filter-${filter.field}=${filter.boolean}-${filter.input}` }
                            filter={filter}
                            setFilters={setCurrentFilters}
                        />
                    )}
                </div>
            </div>
            {searchLoading ? <Loader/> :
                    advancedUsers?.length > 0 && advancedUsers[0]?.err ? <div>{advancedUsers}</div> :
                        advancedUsers?.length > 0 ?
                            <UserSelect 
                                users={advancedUsers}
                                userSearch={userSearch}
                                setUserSearch={setUserSearch}
                            />
                : null
            }
        </div>
    );
}
export default AdvancedSearch;