import RewardLink from 'components/Table/RewardLink';
export default function buildEventsTable(pointRewards, leaderboardRewards, claimedRewards = false, showClaimed = false) {

    const pointHeaders = [
        {
            value: "points",
            label: "Points Required",
            type: "number",
        },
        {
            value: "rewardId",
            label: "Reward",
            type: "number",
            sortBy: "rewardIdValue"
        },
        {
            value: "rewardIdValue",
            type: "number",
            isSort: true
        },
        ...(showClaimed ? [{
            value: 'claimed',
            label: 'Claimed',
            type: 'string'
        }] : [])
    ];

    const leaderboardHeaders = [
        {
            value: "end",
            label: "Rank Requirement",
            type: "number",
        },
        {
            value: "rewardId",
            label: "Reward",
            type: "string",
            sortBy: "rewardIdValue"
        },
        {
            value: "rewardIdValue",
            type: "number",
            isSort: true
        },
        ...(showClaimed ? [{
            value: 'claimed',
            label: 'Claimed',
            type: 'string'
        }] : [])
    ];

    let pointData = [];
    let leaderboardData = [];
    if (pointRewards && leaderboardRewards) {
        pointRewards.forEach(point => {
            pointData.push({
                points: point.Points,
                rewardId: <RewardLink rewardId={point.RewardId} store={1} />,
                rewardIdValue: point.RewardId,
                claimed: claimedRewards?.length > 0 && claimedRewards?.includes(point.RewardId) ? 'True' : 'False'
            });
        });
        leaderboardRewards.forEach(leaderboard => {
            leaderboardData.push({
                start: leaderboard.Start,
                end: leaderboard.End,
                rewardId: <RewardLink rewardId={leaderboard.RewardId} store={1} />,
                rewardIdValue: leaderboard.RewardId,
                claimed: claimedRewards?.length > 0 && claimedRewards?.includes(leaderboard.RewardId) ? 'True' : 'False'
            });
        });
    }
    return [pointData, leaderboardData, pointHeaders, leaderboardHeaders];
}
