import React, { useEffect, useState } from 'react';
import TextInput from 'components/TextInput';
import Table from 'components/Table';
import classes from "../UserMail.module.css";
import getUrl from 'util/getUrl';
import Button from 'components/Button';
import { toast } from 'react-hot-toast';
import useMailbox from 'util/useMailbox';
import useDeletedMail from 'util/useDeletedMail';

function Inbox(props) {
    const {
        playerId,
    } = props;
    const [deleteMail, setDeleteMail] = useState();
    const [disableDelete, setDisableDelete] = useState(true);

    const { data: mailbox, mutate: mutateMailbox } = useMailbox(playerId);
    const { data: deletedMail, mutate: mutateDeleted } = useMailbox(playerId, true);

    const mutate = () => {
        mutateMailbox();
        mutateDeleted();
    }
    async function DeleteMail() {
        try {
            const response = await fetch(`${getUrl()}/Mail/DeleteMail?lPlayerId=${playerId}&lPackageID=${deleteMail}`, { method: 'POST', xhrFields: { withCredentials: true } });
            const data = await response.json();
            if (data) {
                toast.success("Mail deleted.");
                mutate();
            }
            else {
                toast.error("Mail failed to delete.");
            }
        }
        catch (error) {
            toast.error("An error occured while deleting mail.");
            console.log("An error occured while deleting mail.", error); //for debugging
        }
    } 

    //TODO: Delete option should be on checkbox or button on table itself for simplicity
    function updateDeleteMail(packageId) {
        setDeleteMail(packageId);
        setDisableDelete(true);
        for (var i = 0; i < mailbox?.length; ++i) {
            if (parseInt(packageId) === mailbox[i].PackageID) {
                setDisableDelete(false);
                break;
            }
        }
    }
    const mailboxHeaders = [
        { label: "Timestamp", type: "string", value: "Created", defaultSort: true, defaultReverse: true},
        { label: "Sender Id", type: "number", value: "SenderID" },
        { label: "Subject", type: "string", value: "Subject" },
        { label: "Message", type: "string", value: "Message" },
        { label: "Loot", type: "string", value: "Loot", sortBy: "LootValue" },
        { type: "number", value: "LootValue", isSort: true }
    ];


    return (
        <div>
            <div className={classes.spacing}>
                <label>Mailbox</label>
                <Table
                    columns={mailboxHeaders}
                    data={mailbox}
                    styles={{ maxHeight: '30rem' }}
                />
            </div>
            <div className={classes.spacing}>
                <label>Deleted Mail</label>
                <Table
                    columns={mailboxHeaders}
                    data={deletedMail}
                    styles={{ maxHeight: '30rem' }}
                />
            </div>
            {mailbox && mailbox?.length > 0 &&
                <span>
                    <input
                        type="number"
                        placeholder="Package Id"
                        value={deleteMail}
                        onChange={(e) => updateDeleteMail(e.target.value)}
                        className={classes.margins}></input>
                    <Button
                        onClick={DeleteMail}
                        text="Delete Mail"
                        disabled={disableDelete}
                        className={classes.inline}
                    />
                </span>}
        </div>
    );
}
export default Inbox