import useSWR from 'swr';
import getUrl from 'util/getUrl';
import parseAppTree from 'util/parseAppTree';
import fetcher from 'util/fetcher';

export default function useAppTree() {

    const { data, error, isLoading, isValidating, mutate } = useSWR(`${getUrl()}/ObjectEditor/GetAppTree`, (...args) => fetcher(...args).then(res => res.json()));

    const appStates = parseAppTree(data);
    return {
        data: appStates,
        isLoading: isLoading || isValidating,
        isError: error,
        mutate,
    }
}