export default [ //TODO: Should this be overridable?
    {
        label: "25",
        value: 25
    },
    {
        label: "50",
        value: 50 
    },
    {
        label: "100",
        value: 100 
    },
]