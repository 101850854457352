import { useState } from 'react';
import classes from './Note.module.css';
import Button from 'components/Button';
import fetcher from 'util/fetcher';
import getUrl from 'util/getUrl';
import { toast } from 'react-hot-toast';

function AddNote(props) {
    const { isLoading, setIsLoading, mutate, playerId, notes } = props;

    const [message, setMessage] = useState("");
    const [priority, setPriority] = useState(0);

    function createNote() {
        setIsLoading(true);
        fetcher(`${getUrl()}/UserInfo/AddNote?userId=${playerId}&note=${message}&priority=${priority}`)
            .then(res => {
                var response = res.json();
            }).catch(err => {
                console.error("err", err);
                toast.error(`Error: ${err}`);
                setIsLoading(false);
                return;
            });
        toast.success(`Note created successfully!`);
        //TODO: Mutate should insert new note!
        mutate({ data: notes.concat({ Message: message, Priority: priority }) }, false);
        setMessage("");
        setPriority(0);
        setIsLoading(false);
    }

    return (
        <div className={classes.addNote}>
            <textarea
                value={message}
                onChange={e => setMessage(prev => e.target.value )}
            />
            <label>High Priority
                <input type="checkbox" checked={priority} value={priority} onChange={(e) => setPriority(prev => prev === 1 ? 0 : 1)
                }></input>
            </label>
            <Button
                isLoading={isLoading}
                disabled={message.length <= 0}
                onClick={createNote}
                text="Add Note"
            />
        </div>
    );
}

export default AddNote;