import { useState, useEffect, useRef } from 'react';
import classes from './AdvancedOptions.module.css';
import Select from 'components/Select';
import Button from 'components/Button';
import { booleans } from 'constants/advancedSearchOptions';
import useStateData from 'util/useStateData';
import DatePicker from 'components/DatePicker';
import onEnterKeyValidation from 'util/onEnterKeyWithValidation';
function AdvancedOptions(props) {
    const {
        state,
        setCurrentFilters,
        showJson,
        setShowJson,
        playerSearch,
        searchLoading,
        currentJson
    } = props;

    const endInputRef = useRef(null);

    //TODO: Don't render select fields until options load
    const { data: fields } = useStateData(state);
    const [field, setField] = useState(fields?.[0] ?? null);
    const [boolean, setBoolean] = useState(booleans[0]);
    const [input, setInput] = useState("");
    const [endInput, setEndInput] = useState(""); // For range input
    const [date, setDate] = useState(null);
    const [dateRange, setDateRange] = useState(null);

    useEffect(() => {
        if (date) {
            setInput(date.toISOString());
        }
    }, [date]);

    useEffect(() => {
        if (dateRange?.length === 2) {
            setInput(dateRange[0].toISOString());
            setEndInput(dateRange[1].toISOString());
        }
    }, [dateRange]);

    //Set default field once endpoint returns
    if (field === null && fields.length > 0) setField(fields[0]);
    const isRange = boolean?.value?.includes("range");

    const onAddFilter = () => {
        if (!canAddFilter()) return;
        setCurrentFilters(prev => {
            return prev.concat([{
                field: field.value,
                boolean: boolean,
                input: input.concat(boolean.value.includes("range") ? "&" + endInput : "") //Using & as token to split
            }]);
        });
        setInput("");
        setEndInput("");
        setDate(null);
        setDateRange(null);
        setField(fields[0]);
        setBoolean(booleans[0]);
    }

    const onEnterKeySearchFilter = () => {
        if (isRange) {
            endInputRef.current.focus();
        } else {
            onAddFilter();
        }
    }

    const canAddFilter = () => {
        return Boolean((field && boolean?.value && input !== ""));
    }

    return (
        <div className={classes.root}>
            <label> Field
                <Select
                    options={fields}
                    onChange={val => setField(val)}
                    value={field}
                    index={9} 
                />
            </label>
            <label> Boolean
                <Select
                    options={booleans}
                    onChange={val => setBoolean(val)}
                    value={boolean}
                    index={9} 
                />
            </label>
            {field?.isDate === false ?
                <>
                    <input
                        value={input}
                        onChange={e => setInput(e?.target?.value)}
                        onKeyDown={e => onEnterKeyValidation(e, onEnterKeySearchFilter, true)}
                        placeholder={"Enter filter"}
                    />
                    {isRange &&
                        <input
                        value={endInput}
                        placeholder={"Enter filter end range"}
                        onChange={e => setEndInput(e?.target?.value)}
                        onKeyDown={e => onEnterKeyValidation(e, onAddFilter, true)}
                        ref={endInputRef}
                        /> 
                    }
                </>
                : 
                    <DatePicker
                        isRange={isRange}
                        value={isRange ? dateRange : date}
                        onChange={isRange ? setDateRange : setDate}
                    />
            }
            <Button
                text={"Add Filter"}
                onClick={onAddFilter}
                disabled={!(field && boolean?.value && input)} /*TODO: Change disabled so correct field is checked for date*/
            />
            {currentJson &&
                <>
                    <Button
                        text={`${showJson ? 'Hide' : 'Show'} Json`}
                        onClick={() => setShowJson(prev => !prev)}
                    />
                    <Button
                        text={`Search`}
                        onClick={() => playerSearch()}
                        disabled={searchLoading}
                    />
                </>
            }
        </div>
    );
}

export default AdvancedOptions;