import { useState, useEffect, useRef } from 'react';
import classes from './Note.module.css';
import fetcher from 'util/fetcher';
import Button from 'components/Button';
import getUrl from 'util/getUrl';
import { toast } from 'react-hot-toast';

function Note(props) {
    const { note, mutate, isLoading = false, setIsLoading, userId, notes, ...rest } = props;

    const [isEditing, setEditing] = useState(false);
    const [localNote, setLocalNote] = useState(note);

    useEffect(() => {
        setLocalNote(note);
    }, [note])
    
    //TODO: instead of strictly state controlled form, should try only using original note as state
    const updateNote = () => {
        setIsLoading(true);
        fetcher(`${getUrl()}/UserInfo/EditNote?userId=${userId}&note=${localNote.Message}&priority=${localNote.Priority}&noteId=${note.NoteId}`)
            .then(res => {
                var response = res.json();
            }).catch(err => {
                console.error("err", err);
                toast.error(`Error: ${err}`);
                setIsLoading(false);
                return;
            });
        toast.success(`Note updated successfully!`);
        mutate() //TODO: try passing in local update to mutate to avoid weird behavior on refetch, if appears
        setEditing(false);
        setIsLoading(false);
    }

    function deleteNote() {
        setIsLoading(true);
        fetcher(`${getUrl()}/UserInfo/DeleteNote?userId=${userId}&noteId=${note.NoteId}`)
            .then(res => {
                var response = res.json();
            }).catch(err => {
                console.error("err", err);
                toast.error(`Error: ${err}`);
                setIsLoading(false);
                return;
            });
        toast.success(`Note deleted successfully!`);
        mutate({ data: notes.filter(dNote => note.NoteId === dNote.NoteId) }, false);
        setEditing(false);
        setIsLoading(false);
    }

    return (
        <div className={classes.root} {...rest}>
            <div className={classes.noteInfo}>
                <div className={classes.date}>{note.Created}</div>
                <div className={classes.email}>{note.Author}</div>
                <div className={classes.priority}>{localNote.Priority === 1 ? '!' : null}</div>
            </div>
            <textarea
                readOnly={!isEditing}
                value={localNote.Message}
                onChange={
                    (e) => setLocalNote(lNote => { return { ...lNote, Message: e.target.value } })
                }
            />
            <div className={classes.actions}>
                {!isEditing ?
                    <>
                        <Button
                            onClick={() => setEditing(true)}
                            text="Edit"
                            isLoading={isLoading}
                        />
                        <Button
                            onClick={deleteNote}
                            text="Delete"
                            isLoading={isLoading}
                        />
                    </> :
                    <>
                        <label>High Priority
                            <input type="checkbox" checked={localNote.Priority} value={localNote.Priority} onChange={(e) => setLocalNote(lNote => {
                                return {...lNote, Priority: lNote.Priority === 1 ? 0 : 1}})
                            }></input>
                        </label>
                        <Button
                            onClick={updateNote}
                            text="Save Changes"
                        />
                        <Button
                            onClick={() => {
                                setLocalNote(note);
                                setEditing(false);
                            }}
                            text="Cancel"
                        />
                    </>
                }
            </div>
        </div>
    );
}
export default Note;