export default function buildCardTable(ids, cardDefinitions, qualitySettingsData, cardStatSettings, teamSettings) {

    const headers = [
        { label: "ID", type: "number", value: "id" },
        { label: "Name", type: "string", value: "name" },
        { label: "Quality", type: "number", value: "quality" },
        { label: "Team", type: "number", value: "team" },
        { label: "Position", type: "string", value: "position" },
        { label: "Synergy", type: "string", value: "synergy" },
        { label: "Archetype", type: "string", value: "archetype" },
        { label: "Stats", type: "string", value: "stats" }
    ];

    const data = [];

    //TODO: Rework so this can be used for game info - param to get all card Defs?
    ids.forEach(id => {
        const cardDef = cardDefinitions?.CardList?.find(def => def.ID === id);
        if (cardDef) {
            const quality = qualitySettingsData?.QualityList.find(qualityDef => qualityDef.ID === cardDef.Quality);
            const archetype = cardStatSettings?.CardArchetypes.find(arch => arch.ID === cardDef.Archetype);
            const team = teamSettings?.TeamList.find(team => team.ID === cardDef.NFLTeam);
            const cardNode = {
                id: cardDef.ID,
                name: cardDef?.FirstName + " " + cardDef?.LastName,
                quality: `${quality?.NameStr} (${cardDef.Quality})`,
                qualityValue: cardDef.Quality,
                position: cardDef.Position,
                team: `(${cardDef.NFLTeam}) ${team.CityNameStr} ${team.NameStr}`,
                synergy: cardDef.SynergyType, //TODO: use enum for synergy
                archetype: `(${cardDef.Archetype}) ${archetype.Desc}`,
                stats: cardDef.Stats.join(", ")
            }
            data.push(cardNode);
        }
    });
    return [headers, data];
}