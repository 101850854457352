import JsonRow from 'tiles/NBARewind/JsonRow';
import WeightEditInput from 'tiles/NBARewind/WeightEditInput';
import { highlightType } from 'constants/NBARewindTypes';
//TODO: use editing to update weight cells to allow for new values
export default function buildTopPlaysTable(data, editing = false, setNewData = null, gameId = null) {
    const headers = [
        {
            value: "weight",
            label: "Weight",
            type: "number",
            defaultSort: true,
            defaultReverse: true,
            sortBy: "weightValue",
            noSort: editing
        },
        {
            value: "weightValue",
            type: "number",
            isSort: true,
            noSort: editing
        },
        ...setNewData ? [{ //No description on selected highlights
            value: "description",
            label: "Description",
            type: "string",
            noSort: editing
        }] : [],
        {
            value: "type",
            label: "Highlight Type",
            type: "string",
            noSort: editing
        },
        {
            value: "player",
            label: "Player",
            type: "string",
            noSort: editing
        },
        ...setNewData ? [{ //Hide on selected highlights for space
            value: "period",
            label: "Period",
            type: "number",
            noSort: editing
        }] : [],
        ...setNewData ? [{
            value: "clock",
            label: "Clock Time",
            type: "string",
            noSort: editing
        }] : [],
        ...!setNewData ? [{
            value: "game",
            label: "Game",
            type: "string",
        }] : [],
        ...setNewData ? [{
            value: "json",
            label: "Json",
            type: "string",
            noSort: true
        }] : [],
    ];

    const rows = data?.map(highlight => {
        const { Weight, Description, Type, FeaturedPlayer, NBAPlayer, PeriodNumber, Period, GameEventDetails } = highlight;
        const prettyType = highlightType?.[Type];
        const desc = Description ?? 'N/A';
        const player = FeaturedPlayer?.FullName ?? NBAPlayer?.FullName ?? 'N/A';
        const clockMin = highlight?.Clock_Minutes ? `${highlight?.Clock_Minutes}:${highlight?.Clock_Seconds < 10 ? '0' : ''}${highlight?.Clock_Seconds}` : null;

        return {
            weight: editing ?
                <WeightEditInput
                    highlight={highlight}
                    weight={Weight}
                    setNewData={setNewData}
                    gameId={gameId}
                />
                : Weight.toFixed(2),
            weightValue: Weight,
            description: desc,
            type: prettyType,
            player: player,
            period: PeriodNumber ?? Period ?? 'N/A',
            clock: GameEventDetails?.clock ?? clockMin ?? "N/A",
            game: !setNewData ? highlight?.game : null,
            json: <JsonRow
                parent={data}
                json={highlight}
                title={`${prettyType}${player ? " - " + player : ""}`}
            />,
        };
    });
    return [headers, rows];
}