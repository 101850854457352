import React, { useEffect, useState } from 'react';
import CatImage from './Images/Cat_Icon.jpg';
import PhoenixImage from './Images/PhoenixIcon.jpg';
import SplashImage from './Images/Splash_Tile_Image.png';
import classes from "./GameImage.module.css";

function GameImage(props) {
    const { game } = props;
    let Image = CatImage;
    if (game === "phoenix") Image = PhoenixImage;
    else if (game === "splash") Image = SplashImage;

    return (
        <div className={classes.game_image_container}>
            < img src={Image} alt="Game_Image" className={classes.game_image_default} />
        </div>
    );
}
export default GameImage