export default function buildMentorTable(mentorList) {
    const headers = [
        {
            value: 'cardDefId',
            label: 'CardDefID',
            type: 'number'
        },
        {
            value: 'level',
            label: "Level",
            type: 'number'
        }
    ];
    const data = [];
    mentorList?.forEach((mentor, i) => {
        const mentorNode = {
            cardDefId: mentor.CardDefID,
            level: mentor.Level,
        }
        data.push(mentorNode);
    })
    return [headers, data];
}