import React, { useState } from 'react';
import classes from "./UserMail.module.css";
import SendMail from './UserMail/SendMail';
import Inbox from './UserMail/Inbox';
import MailFeedback from './UserMail/MailFeedback';
import PlayerSearch from './UserInfo/PlayerSearch';
import Loader from 'components/Loader';
import Tabs from 'components/Tabs/Tabs';
import useMailbox from 'util/useMailbox';
import useDeletedMail from 'util/useDeletedMail';

function UserMail() {
    const [playerId, setPlayerId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isMassMail, setIsMassMail] = useState(false);
    const [massMailIds, setMassMailIds] = useState("");
    const tabValues = [
        { name: 'inbox', label: 'Inbox' },
        { name: 'sendMail', label: 'Send Mail' },
        { name: 'feedback', label: 'Send Feedback'}
    ];
    const [selectedTab, setSelectedTab] = useState(tabValues[0].name);

    const { mutate: mutateMailbox } = useMailbox(playerId);
    const { mutate: mutateDeleted } = useDeletedMail(playerId);

    //Use to update mail inboxes after sending mail, if user is still being searched on standard flow
    const mutateMail = () => {
        mutateMailbox();
        mutateDeleted();
    }

    return (
        <div className={classes.root}>
            <label className={classes.mass_mail_check}><input type="checkbox" onChange={(e) => setIsMassMail(e.target.checked)} /> Mass Mail</label>
            <div className={`${classes.spacing_bottom} ${isMassMail ? classes.hidden : ''}`}>
                <PlayerSearch
                    userSearch={playerId}
                    setUserSearch={setPlayerId}
                    sharedOption={true}
                    isLoading={isLoading}
                    mutate={mutateMail}
                />
            </div>
            {isLoading ?
                <Loader /> :
                <div>
                    {isMassMail ?
                        <div>
                            <div className={classes.spacing}>
                                <div>
                                    <label>Player ID(s):</label>
                                </div>
                                    <textarea className={classes.mass_mail_ids} onChange={(val) => setMassMailIds(val.target.value)} />
                            </div>
                            <SendMail
                                playerId={-1}
                                isMassMail={isMassMail}
                                massMailIds={massMailIds}
                                mutate={mutateMail}
                            />
                        </div> 
                        : <div>
                            <Tabs
                                tabValues={tabValues}
                                selectedTab={selectedTab}
                                setSelected={setSelectedTab}
                            />

                            {selectedTab === tabValues[0].name ?
                                <Inbox
                                    playerId={playerId}
                                />
                                : selectedTab === tabValues[1].name ?
                                    <SendMail
                                        playerId={playerId}
                                        isMassMail={isMassMail}
                                        massMailIds={massMailIds}
                                        mutate={mutateMail}
                                    />
                                    : selectedTab === tabValues[2].name ?
                                        <MailFeedback
                                        />
                                       : ''
                            }
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default UserMail