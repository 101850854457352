import { DropType as DropTypes, LootType, SpreadContexts } from 'constants/phoenixTypes';
export default function buildRewardTable(reward, cardDefinitions, currencyDefinitions, equipmentDefinitions, qualitySettings, playDefinitions) {
    const headers = [
        {
            value: "dropType",
            label: "Drop Type",
            type: "number"
        },
        {
            value: "dropAmount",
            label: "Drop Amount",
            type: "number"
        },
        {
            value: "dropDescription",
            label: "Description",
            type: "string"
        },
    ];

    const itemHeaders = [
        {
            value: "mainType",
            label: "Main Type",
            type: "number",
        },
        {
            value: "subValue",
            label: "Type",
            type: "string",
        },
        {
            value: "quality",
            label: "Quality",
            type: "string"
        },
        {
            value: "itemAmount",
            label: "Amount",
            type: "number",
        },
        {
            value: "dropChance",
            label: "Drop Chance",
            type: "string",
        },
    ];

    const data = [];

    function getQuality(id) {
        return qualitySettings?.QualityList?.find(item => item.ID === id);
    }

    //TODO: Should spread weights be shown?
    function getSpreadQuality(context, floor, ceiling, spread) {
        const type = SpreadContexts[context];
        var floorQuality, ceilQuality;
        floorQuality = getQuality(floor);
        ceilQuality = getQuality(ceiling);
        if (type.includes("ABSOLUTE") || spread?.length === 0) { //If relative has no spread array, then treat as abs
            if (floor === ceiling) return `${floorQuality?.NameStr} (${floor})`;
            return `${floorQuality.NameStr} (${floor}) - ${ceilQuality.NameStr} (${ceiling})`;
        } else if (type.includes("RELATIVE")) {
            const first = spread[0];
            return `Top Deck Quality ${first?.value >= 0 ? '+' : '-'} ${Math.abs(spread[0].Value)} (${floorQuality.NameStr} - ${ceilQuality.NameStr})`;
        } else if (type.includes("SPECIFIC_ID")) {
            //TODO: Nothing in store is using this, so only here as fallback for now
            return "Quality of item rolled";
        }
        return 'N/A';
    }
    reward?.Loot.Drops.map((drop, i) => {
        const { DropType, DropAmount, DropDescription, Items } = drop;
        const nodes = Items.map(item => {
            const { MainType, SubValue, ItemAmount, DropChance, SpreadCeiling, SpreadFloor, SpreadContext, Spread } = item;
            const type = LootType[MainType];
            var sub = SubValue;
            var quality = 'N/A';
            if (type.includes("CURRENCY")) {
                const currency = currencyDefinitions.Currencies.find(curr => curr.CurrencyType === SubValue)
                sub = `${currency.CurrencyName.replace("_NAME", "")} (${SubValue})`;
            } else if (type.includes("EQUIPMENT_SPECIFIC")) {
                const equip = equipmentDefinitions.EquipmentList.find(item => item.ID === SubValue);
                if (equip) {
                    sub = equip.Name;
                    quality = `${getQuality(equip.Quality).NameStr} (${equip.Quality})`;
                }
            } else if (type.includes("CARD_SPECIFIC")) {
                const card = cardDefinitions.CardList.find(item => item.ID === SubValue);
                if (card) {
                    sub = `${card?.FirstName} ${card?.LastName} (${SubValue})`;
                    quality = `${getQuality(card.Quality).NameStr} (${card.Quality})`;
                }
            } else if (type.includes("PLAY_SPECIFIC")) {
                const play = playDefinitions.PlayCardList.find(item => item.ID === SubValue);
                if (play) {
                    sub = `${play?.Name} (${SubValue})`
                    quality = `${getQuality(play.Quality).NameStr} (${play.Quality})`;
                }
            } else if (type.includes("RANDOM")) {
                quality = getSpreadQuality(SpreadContext, SpreadFloor, SpreadCeiling, Spread);
                sub = 'N/A'; //Assuming subtype is never used for random drops, as consistently set to 0
            }
            return {
                id: i,
                mainType: type,
                subValue: sub,
                itemAmount: ItemAmount,
                dropChanceValue: DropChance,
                quality: quality,
                dropChance: `${DropChance * 100}%`
            };
        });

        data.push({
            id: i,
            dropType: DropTypes[DropType],
            dropAmount: DropAmount,
            dropDescription: DropDescription,
            nodes
        })
    });
    return [headers, itemHeaders, data];
}