export default function buildEquipmentTable(equipmentList, equipmentDefinitions, qualitySettings) {
    const headers = [
        {
            value: 'id',
            label: 'ID',
            type: 'number'
        },//TODO: Add instance id when avaliable
        {
            value: "quality",
            sortBy: "qualityValue",
            label: "Quality",
            type: "string"
        },
        {
            value: "qualityValue",
            type: "number",
            isSort: true
        },
        {
            value: 'name',
            label: "Name",
            type: 'string'
        },
        {
            value: "attachedCardId",
            label: "AttachedCardID",
            type: 'number'
        }
    ];
    const data = [];
    equipmentList?.forEach((equip, i) => {
        const equipDef = equipmentDefinitions.EquipmentList.find(def => def.ID === equip.EquipmentDefID);
        const { Name, Quality } = equipDef;
        const quality = qualitySettings.QualityList.find(def => def.ID === Quality);
        const equipNode = {
            id: equip.EquipmentDefID,
            instanceId: equip.InstanceID,
            attachedCardId: equip?.AttachedCardID ?? 'N/A',
            name: Name,
            quality: `${quality?.NameStr} (${Quality})`, //TODO: Make generic formatter util for quality
            qualityValue: Quality

        }
        data.push(equipNode);
    })
    return [headers, data];
}