import classes from './WeightEditButton.module.css';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

function WeightEditButton(props) {
    const { editing, setEditing, onSubmit, setNewData, data } = props;

    return (
        <div className={classes.editingWrapper}>
            {editing ? <>
                <Button
                    onClick={() => {
                        setEditing(false);
                        setNewData(structuredClone(data));
                    }}
                    text={<><FontAwesomeIcon
                        icon={faX}
                        className={classes.x}
                    /> {`Cancel`} </>}
                />
                <Button
                    onClick={() => {
                        setEditing(false);
                        onSubmit();
                    }}
                    text={<><FontAwesomeIcon
                        icon={faCheck}
                        className={classes.check}
                    />{`Save Changes`}</>}
                />
            </> :
                <Button
                    onClick={() => setEditing(true)}
                    text={"Edit Weights"}
                />
            } 
        </div>
    )
}

export default WeightEditButton;